const initState = () => ({ balance: 0 });

export default {
  state: initState,
  mutations: {
    clearUserState(state) {
      Object.keys(state).forEach((key) => (state[key] = null));
    },
    setUserState(state, payload) {
      Object.assign(state, payload);
    }
  },
  getters: {
    userData: (state) => state
  }
};
