<template>
  <button
    class="btn"
    :type="htmlType"
    :disabled="disabled"
    :class="[
      { btn_primary: type === 'primary' },
      { btn_default: type == null || type === 'default' },
      { 'btn_max-width': maxWidth },
      { btn_loading: loading },
      { btn_small: size === 'small' }
    ]"
  >
    <i v-if="icon" class="btn__icon" :class="`pi ${icon}`" />
    <loading-outlined v-if="loading" class="btn__icon" />
    <span v-if="$slots.default"><slot /></span>
  </button>
</template>

<script>
import { LoadingOutlined } from "@ant-design/icons-vue";

export default {
  components: { LoadingOutlined },
  props: {
    type: String,
    loading: Boolean,
    disabled: Boolean,
    maxWidth: Boolean,
    icon: String,
    htmlType: {
      type: String,
      default: "button"
    },
    size: {
      type: String,
      default: "normal"
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  cursor: pointer;
  position: relative;
  padding: 10px 16px;
  display: inline-block;
  white-space: nowrap;
  outline: 0;
  border-radius: var(--border-radius-base);
  transition: var(--transition-base);
  border: 1px solid transparent;
  font-weight: 500;

  &_small {
    padding: 6px 12px;
  }

  &_loading {
    pointer-events: none;
    color: var(--button-loading-color);
  }

  &_max-width {
    width: 100%;
  }

  &__icon:not(:last-child) {
    margin-right: 10px;
  }

  &_primary {
    background-color: var(--primary-color);

    span {
      color: #fff;
    }

    &:hover {
      background-color: var(--primary-hover-color);
    }
  }

  &_default {
    color: var(--primary-text-color);
    border: 1px solid var(--button-default-background-color);
    background: transparent;

    &:hover {
      background-color: var(--button-default-hover-background-color);
    }

    &:active {
      scale: 0.96;
    }
  }

  &[disabled] {
    pointer-events: none;
    background-color: var(--button-disabled-background);
    color: var(--button-disabled-text-color);

    &:deep(span) {
      color: var(--button-disabled-text-color);
    }
  }
}
</style>
