<template>
  <base-dropdown-menu
    :first-title="$currencyFormat(balance, 'RUB')"
    class="language-selection"
    :data="data"
    icon="bi bi-wallet2"
    hide-icon
  />

  <base-modal-window :is-open="isOpen" size="small" center>
    <add-funds-form @cancel="isOpen = false" />
  </base-modal-window>
</template>

<script>
import { currentUser } from "@/main";
import { doc, getDoc, getFirestore, onSnapshot } from "@firebase/firestore";
import BaseDropdownMenu from "../Base/BaseDropdownMenu.vue";
import { computed } from "@vue/reactivity";
import BaseModalWindow from "../Base/BaseModalWindow.vue";
import AddFundsForm from "./AddFundsForm.vue";
import { ref } from "vue";
import { mapMutations } from "vuex";

export default {
  components: { BaseDropdownMenu, BaseModalWindow, AddFundsForm },
  async setup() {
    const docRef = doc(getFirestore(), "users", currentUser.uid, "private", "finances");
    const balance = (await getDoc(docRef)).data()?.private?.balance || 0;
    return ref({ balance, docRef }).value;
  },
  data() {
    return {
      isOpen: false,
      data: [
        {
          name: computed(() => this.$i18n.t("navigationLabel.payments")),
          key: "payments",
          event: this.goToPayments,
          icon: "bi bi-credit-card"
        },
        {
          name: computed(() => this.$i18n.t("payments.addFunds")),
          key: "replenish-balance",
          event: this.openForm,
          icon: "bi bi-plus-circle"
        }
      ]
    };
  },
  mounted() {
    onSnapshot(this.docRef, (doc) => {
      const balance = doc.data()?.balance || 0;
      this.setUserState({ balance });
      this.balance = balance;
    });
  },
  methods: {
    ...mapMutations(["setUserState"]),
    goToPayments() {
      this.$router.push("/payments");
    },
    openForm() {
      this.isOpen = true;
    }
  }
};
</script>
