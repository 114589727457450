import lodash from "lodash";

const initState = () => ({
  product_id: null,
  period: null,
  csr: null,
  approver_email: null,
  single_san_count: null,
  wildcard_san_count: null,
  csrResult: null,
  dcv_method: "email",
  emailsValidation: null,
  productData: null,
  totalPrice: 0,
  contacts: {
    admin_firstname: "",
    admin_lastname: "",
    admin_organization: "",
    admin_addressline1: "",
    admin_phone: "",
    admin_title: "",
    admin_email: "",
    admin_city: "",
    admin_postalcode: "",
    admin_region: "",
    tech_firstname: "",
    tech_lastname: "",
    tech_organization: "",
    tech_addressline1: "",
    tech_phone: "",
    tech_title: "",
    tech_email: "",
    tech_city: "",
    tech_postalcode: "",
    tech_region: "",
    org_name: "",
    org_division: "",
    org_duns: "",
    org_addressline1: "",
    org_city: "",
    org_country: "",
    org_phone: "",
    org_postalcode: "",
    org_region: "",
    org_lei: "",
    admin_country: null,
    tech_country: null,
    isSame: true
  }
});

export const twoWayProperty = (key) => ({
  get() {
    return lodash.result(this.$store.state.orderSSL, key);
  },
  set(value) {
    this.$store.commit("setOrderState", lodash.set(this.$store.state.orderSSL, key, value));
  }
});

export default {
  state: initState,
  mutations: {
    clearOrderState(state) {
      Object.assign(state, initState());
    },
    setOrderState(state, payload) {
      Object.assign(state, payload);
    }
  },
  getters: {
    orderSSL: (state) => state
  }
};
