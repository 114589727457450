<template>
  <Transition name="fade" mode="out-in">
    <div
      v-if="isOpen"
      ref="modalWindow"
      class="modal-window"
      :class="[{ 'modal-window_center': center }]"
      @click="clickOutside"
    >
      <div
        v-if="isOpen"
        class="modal-window__container"
        :class="[
          { 'modal-window__container_default': size === 'default' },
          { 'modal-window__container_small': size === 'small' }
        ]"
      >
        <div class="modal-window__container-header">
          <h2 v-if="title">
            {{ title }}
          </h2>
          <i v-if="cancel" class="bi bi-x-lg" @click="$emit('close')" />
        </div>
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    cancel: Boolean,
    center: Boolean,
    title: String,
    size: {
      type: String,
      default: "default"
    }
  },
  emits: ["close"],
  methods: {
    clickOutside(event) {
      if (event.target === this.$refs.modalWindow && this.cancel) this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-window {
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 10000;

  &_center {
    display: flex;
    align-items: center;
  }

  &__container {
    position: relative;
    box-sizing: border-box;
    margin: 50px auto;
    background-color: var(--canvas-default-color);
    padding: 20px;
    border-radius: var(--border-radius-base);

    &-header h2 {
      margin-bottom: 20px;
    }

    &_default {
      width: 500px;
    }

    &_small {
      width: 440px;
    }

    .bi.bi-x-lg {
      cursor: pointer;
      position: absolute;
      top: 20px;
      font-size: 16px;
      right: 20px;
      color: var(--primary-text-second-color);
    }
  }
}

@media (max-width: 480px) {
  .modal-window__container {
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: 0;
    overflow: auto;
  }
}
</style>
