<template>
  <div class="side-navigation">
    <div class="side-navigation__inner">
      <a href="/" class="side-navigation__header-logo">
        <img :src="AdsGroupLogo" alt="ads-group" />
      </a>

      <base-segmented-picker
        v-if="isAdmin"
        v-model:selected="selectedMenu"
        :list="listMenu"
        name="menu-picker"
        class="side-navigation__menu-picker"
      />

      <div class="side-navigation__control">
        <header-main-right />
      </div>

      <ul v-if="selectedMenu === UserRules.USER" class="side-navigation__list">
        <side-navigation-item :menu="menu" @toggle-side-bar="$emit('update:isOpenSideBar', false)" />
      </ul>

      <ul v-if="isAdmin && selectedMenu === UserRules.ADMIN" class="side-navigation__list">
        <side-navigation-item :menu="menuAdmin" @toggle-side-bar="$emit('update:isOpenSideBar', false)" />
      </ul>
    </div>
  </div>
  <div v-if="isOpenSideBar" class="side-navigation__blackout" @click="$emit('update:isOpenSideBar', false)" />
</template>

<script>
import AdsGroupLogo from "@/assets/logo/ads-group-logo.png";
import BaseIcons from "@/assets/icons/base-icons.svg";
import SideNavigationItem from "./SideNavigationItem.vue";
import BaseSegmentedPicker from "@/components/Base/BaseSegmentedPicker.vue";
import HeaderMainRight from "../Headers/HeaderMainRight.vue";
import { currentUserIsAdmin } from "@/main";

const UserRules = { ADMIN: "admin", USER: "user" };

export default {
  components: { SideNavigationItem, BaseSegmentedPicker, HeaderMainRight },
  props: {
    label: String,
    menu: Object,
    menuAdmin: Object,
    icon: String,
    isOpenSideBar: Boolean,
    iconID: String
  },
  emits: ["update:isOpenSideBar"],
  data() {
    return {
      UserRules,
      isAdmin: currentUserIsAdmin,
      selectedMenu: "user",
      listMenu: [
        { label: "Пользователь", key: UserRules.USER },
        { label: "Админ", key: UserRules.ADMIN }
      ],
      BaseIcons,
      AdsGroupLogo
    };
  },
  mounted() {
    if (
      this.isAdmin &&
      this.menuAdmin.flatMap((item) => item.children || item).some((item) => item.path === this.$route.path)
    )
      this.selectedMenu = UserRules.ADMIN;

    (this.selectedMenu === UserRules.ADMIN ? this.menuAdmin : this.menu).map((item) => {
      item.children && item.children.find((subItem) => subItem.path === this.$route.path) && (item.isOpen = true);
    });
  }
};
</script>

<style lang="scss" scoped>
.side-navigation {
  background-color: var(--sidebar-background-color);
  width: 320px;
  height: 100%;
  box-sizing: border-box;
  z-index: 1000;
  overflow: auto;

  &__control {
    display: none;
    border-radius: var(--border-radius-base);
    background-color: var(--body-background);
    padding: 6px;
    margin-bottom: 20px;
  }

  &__menu-picker {
    margin-bottom: 30px;
  }

  &__inner {
    padding: 30px;
    box-sizing: border-box;
  }

  &__header-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    margin-bottom: 20px;

    & img {
      width: 230px;
    }
  }

  &__list {
    list-style: none;
  }
}

@media (max-width: 850px) {
  .side-navigation {
    position: fixed;

    &__control {
      display: block;
    }

    &__blackout {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-events: all;
      background-color: black;
      opacity: 0.3;
      z-index: 9;
    }
  }
}
</style>
