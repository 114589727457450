import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/router";
import { initializeApp } from "firebase/app";
import { setupI18n } from "./i18n";
import { v4 as uuidv4 } from "uuid";
import "primeicons/primeicons.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { currencyFormat } from "@/utils/currencyFormatter";
import { getAuth } from "firebase/auth";
import store from "./store";
import config from "./utils/config";
import "vue3-toastify/dist/index.css";
import Vue3Toastify from "vue3-toastify";

initializeApp(config.firebase);

!localStorage.getItem("session_id") && localStorage.setItem("session_id", uuidv4());

const app = createApp(App);

export let currentUser;
export let currentUserIsAdmin;

export const API_URL_GOGETSSL = "https://my.gogetssl.com/api";
export const API_URL_R01 = "https://api.r01.ru/api/v1";
export const serverURL = process.env.VUE_APP_API_URL;

export const setCurrentUser = async () => {
  const user = new Promise((resolve) => {
    getAuth().onAuthStateChanged((user) => {
      currentUser = user;

      if (user)
        user.getIdTokenResult(true).then((idTokenResult) => {
          currentUserIsAdmin = idTokenResult?.claims.admin;
          resolve();
        });
      else resolve();
    });
  });

  const isAdmin = new Promise((resolve) => getAuth().onIdTokenChanged(resolve));
  return await Promise.all([user, isAdmin]);
};

app.use(Vue3Toastify, { autoClose: 3000, theme: "auto" });
app.use(router);
app.use(store);
app.use(setupI18n());
app.config.globalProperties.$currencyFormat = currencyFormat;
app.mount("#app");
