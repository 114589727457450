import { currentUser } from "@/main";
import axios from "axios";
import { toast } from "vue3-toastify";
import { serverURL } from "@/main";

const requestInstance = axios.create({ baseURL: "a" });

requestInstance.interceptors.request.use((config) => {
  config.baseURL = serverURL;
  return config;
});

requestInstance.interceptors.request.use((config) => {
  const accessToken = currentUser?.accessToken;
  if (config && config.headers && accessToken) config.headers.Authorization = accessToken;
  return config;
});

requestInstance.interceptors.response.use(
  (response) => {
    if (response.data?.message) toast.success(response.data.message);
    return response;
  },
  (error) => {
    error?.response?.data?.message && toast.error(error.response.data.message);
    return Promise.reject(error);
  }
);

export { requestInstance };
