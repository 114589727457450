<template>
  <button :class="{ 'button-circle-danger': danger }" class="button-circle" type="button">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    danger: Boolean
  }
};
</script>

<style lang="scss" scoped>
.button-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transition-base);
  background-color: var(--header-control-background-color);

  &:deep(i) {
    font-size: 14px;
  }

  &-danger {
    background: transparent;

    &:deep(i) {
      color: var(--input-error-color);
    }
  }

  &:hover {
    background-color: var(--header-control-hover-background-color);
  }
}
</style>
