<template>
  <div class="header-control">
    <language-selection />

    <theme-switcher />

    <router-link to="/profile">
      <base-button-circle>
        <i class="bi bi-person" />
      </base-button-circle>
    </router-link>

    <router-link to="/support">
      <base-button-circle>
        <i class="bi bi-question-circle" />
      </base-button-circle>
    </router-link>

    <base-button-circle @click="signOutAccount">
      <i class="bi bi-power" />
    </base-button-circle>

    <balance-button />
  </div>
</template>

<script>
import { getAuth, signOut } from "@firebase/auth";
import BaseButtonCircle from "../Base/BaseButtonCircle.vue";
import LanguageSelection from "../Controls/LanguageSelection.vue";
import ThemeSwitcher from "../Controls/ThemeSwitcher.vue";
import BalanceButton from "../Controls/BalanceButton.vue";

export default {
  components: { LanguageSelection, ThemeSwitcher, BaseButtonCircle, BalanceButton },
  methods: {
    signOutAccount() {
      signOut(getAuth()).then(() => this.$router.push("/login"));
    }
  }
};
</script>

<style lang="scss" scoped>
.header-control {
  display: flex;
  flex-direction: row;
  gap: 14px;
  flex-wrap: wrap;
}
</style>
