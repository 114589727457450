<template>
  <header class="header">
    <nav class="header__inner">
      <div class="header__inner-left-side">
        <i class="header__mobile-menu pi pi-bars" @click="$emit('toggleSideBar')" />
        <h1>{{ $t(label) }}</h1>
      </div>
      <div class="header__inner-right-side">
        <header-main-right />
      </div>
    </nav>
  </header>
</template>

<script>
import AdsGroupLogo from "@/assets/logo/ads-group-logo.png";
import HeaderMainRight from "./HeaderMainRight.vue";

export default {
  components: {
    HeaderMainRight
  },
  props: {
    label: String
  },
  emits: ["toggleSideBar"],
  data() {
    return {
      AdsGroupLogo
    };
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--page-header-border-color);

  &__mobile-menu {
    display: none;
    font-size: 20px;
    cursor: pointer;
  }

  &__signout-btn {
    width: 80px;
    padding: 8px;
  }

  &__openmenu-btn {
    padding: 10px;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    flex-wrap: wrap;
    gap: 10px;

    &-right-side {
      display: block;
    }

    &-left-side {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }
  }
}

@media (max-width: 850px) {
  .header {
    &__mobile-menu {
      display: block;
    }

    &__inner-right-side {
      display: none;
    }

    padding-bottom: 10px;
  }
}
</style>
