<template>
  <div class="funds-container">
    <h3>{{ $t("payments.addFundsToAccount") }}</h3>
    <form @submit.prevent="submit">
      <base-input id="amount" v-model="amount" label="Amount" number variant="filled" placeholder="0.00" />
      <form-control
        :loading="formLoading"
        max-width
        :label-submit="$t('payments.pay')"
        :disabled="!checkForm()"
        @cancel="$emit('cancel')"
      />
    </form>
  </div>
</template>

<script>
import { requestInstance } from "@/utils/api.helper";
import BaseInput from "../Base/BaseInput.vue";
import FormControl from "../Form/FormControl.vue";

export default {
  components: { BaseInput, FormControl },
  props: {
    uid: String
  },
  emits: ["cancel", "success"],
  data() {
    return {
      amount: "",
      formLoading: false
    };
  },
  methods: {
    submit() {
      this.formLoading = true;

      if (this.uid !== undefined) {
        requestInstance
          .post("/admin/users/add_funds", { amount: this.amount, user: this.uid })
          .then(() => this.$emit("success"))
          .finally(() => (this.formLoading = false));
      } else {
        requestInstance
          .post("/payment/bill", { amount: this.amount })
          .then(({ data }) => (window.location.href = data.url))
          .finally(() => (this.formLoading = false));
      }
    },
    checkForm() {
      return this.amount !== "" && this.amount > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.funds-container {
  h3 {
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
