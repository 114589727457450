<template>
  <div
    class="select-input"
    :class="[
      { 'select-input_border': border, 'select-input_disable': disable },
      { 'select-input_filled': variant === 'filled' }
    ]"
  >
    <span v-if="label" class="select-input__label">{{ label }}</span>
    <button
      ref="button"
      class="select-input__button"
      :class="{ 'select-input__button_active': isOpen }"
      type="button"
      @click="openHandler"
    >
      <i v-if="icon" :class="icon" class="select-input__button-icon-before" />

      <span v-if="loading">{{ $t("button.loading") }}</span>
      <span v-else-if="firstTitle !== undefined && !selectedKey">{{ firstTitle }}</span>

      <div v-else-if="getSelectedItem().name && getSelectedItem().icon">
        <i :class="getSelectedItem().icon" />
        <span> {{ getSelectedItem().name }}</span>
      </div>

      <span v-else>{{ getSelectedItem().name }}</span>

      <i v-if="loading" class="pi pi-spin pi-spinner select-input__button-icon-after" />
      <i v-else-if="!hideIcon" class="pi pi-angle-down select-input__button-icon-after" :class="{ open: isOpen }" />
    </button>

    <Transition mode="out-in">
      <div
        v-if="isOpen"
        ref="selectList"
        class="select-input__container"
        :class="{ 'select-input__container_center': textCenter }"
      >
        <div v-if="search" class="select-input__container-filter">
          <base-input v-model="filter" search />
        </div>

        <ul v-if="isOpen">
          <li
            v-for="item in data?.filter((elem) => elem.name.toLowerCase().includes(filter.toLowerCase()))"
            :key="item.key"
            :class="{ 'select-input__item_selected': selectedKey === item.key }"
            @click="selected(item)"
          >
            <i v-if="item.icon" :class="item.icon" />
            {{ item.name }}
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script>
import BaseInput from "./BaseInput.vue";

export default {
  components: { BaseInput },
  props: {
    data: Array,
    disable: Boolean,
    selectedKey: [String, Number],
    border: Boolean,
    textCenter: Boolean,
    hideIcon: Boolean,
    firstTitle: String,
    search: Boolean,
    loading: Boolean,
    label: String,
    icon: String,
    variant: {
      type: String,
      default: "default"
    }
  },
  emits: ["update:selectedKey", "selected"],
  data() {
    return {
      isOpen: false,
      filter: ""
    };
  },
  created() {
    window.addEventListener("click", (event) => this.isOpen && (this.isOpen = this.$el.contains(event.target)));
  },
  methods: {
    openHandler() {
      if (!this.loading && !this.disable) this.isOpen = !this.isOpen;

      if (this.isOpen) {
        this.$nextTick(() => {
          const element = this.$refs.selectList;
          const rect = element.getBoundingClientRect();

          if (rect.left < 0) element.style.left = "0px";
          if (rect.right > window.innerWidth) element.style.right = "0px";
          if (rect.bottom > window.innerHeight) element.style.bottom = `${this.$refs.button.clientHeight + 8}px`;
        });
      }
    },
    selected(item) {
      if (!item.event) {
        this.$emit("selected", item);
        this.$emit("update:selectedKey", item.key);
      } else item.event();
      this.isOpen = false;
    },
    getSelectedItem() {
      return this.data.find((elem) => elem.key === (this.selectedKey || this.data[0].key));
    }
  }
};
</script>

<style lang="scss" scoped>
.select-input {
  font-size: 14px;
  border-radius: var(--border-radius-base);
  transition: var(--transition-base);
  position: relative;

  &__item_selected {
    background-color: var(--primary-color);
    color: white;
  }

  &_filled &__button {
    background-color: var(--input-background-filled-color);
  }

  &_disable {
    opacity: 0.5;
    pointer-events: none;
  }

  i:first-child {
    font-size: 14px;
    margin-right: 10px;
  }

  &__label {
    font-weight: 500;
    font-size: 14px;
    color: var(--primary-text-second-color);
    display: inline-block;
    margin-bottom: 6px;
  }

  &_border {
    .select-input {
      &__button {
        background-color: transparent !important;
        border: 1px solid var(--input-border-color);

        &_active {
          outline: 0;
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px var(--primary-border-color);
        }
      }

      &__container {
        border: 1px solid var(--input-border-color);
      }
    }
  }

  &__container {
    position: absolute;
    margin-top: 10px;
    min-width: 100%;
    box-shadow: var(--dropdown-menu-shadow);
    background: var(--canvas-default-color);
    box-sizing: border-box;
    border-radius: var(--border-radius-base);
    z-index: 8;

    &-filter {
      padding: 10px;
      border-bottom: 1px solid var(--input-border-color);
    }

    &_center ul {
      text-align: center;
    }

    ul {
      padding: 6px 0;
      max-height: 250px;
      list-style: none;
      overflow: auto;

      & li {
        padding: 12px 12px;
        transition: var(--transition-base);
        cursor: pointer;
        white-space: nowrap;

        & .pi-spinner {
          font-size: 18px;
        }

        &:hover:not(.select-input__item_selected) {
          background-color: var(--button-default-hover-background-color);
        }
      }
    }
  }

  &__button {
    cursor: pointer;
    border: none;
    width: 100%;
    min-height: 34px;
    padding: 0 12px;
    border-radius: var(--border-radius-base);
    transition: var(--transition-base);
    background-color: var(--canvas-default-color);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    height: 100%;

    &-icon {
      transition: transform 0.2s ease-in-out;
      font-size: 14px;

      &-after {
        margin-left: 10px;
      }

      &-before {
        margin-right: 10px;
      }

      &.open {
        transform: rotate(-180deg);
      }
    }

    span {
      padding: 8px 0;
    }

    &:hover {
      background: var(--button-default-hover-background-color);
    }
  }
}
</style>
