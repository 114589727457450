<template>
  <div class="input-container" :class="{ 'input-container_disable': disabled }">
    <label v-if="label && id" :for="id">{{ label }}</label>

    <div
      class="input-container__field"
      :class="{
        'input-container__field_filled': variant === 'filled',
        'input-container__field_outlined': variant === 'outlined'
      }"
    >
      <i v-if="search" class="bi bi-search input-container__field-icon" />
      <i v-if="icon" :class="icon" class="input-container__field-icon" />
      <input
        :id="id"
        :autofocus="autofocus"
        :maxlength="maxlength"
        :class="[{ error: errorMessage }, { loading: loading }]"
        :placeholder="placeholder"
        :type="number ? 'number' : 'text'"
        :value="modelValue"
        :disabled="disabled || loading"
        :readonly="readonly"
        @blur="$emit('blur')"
        @input="inputHandler"
      />
      <i v-if="loading" class="pi pi-spin pi-spinner input-container__field-loading-icon" />
    </div>

    <span v-if="helpText" class="input-container__help-text">{{ helpText }}</span>
    <span v-if="errorMessage" class="input-container__error-message">{{ $t(errorMessage) }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    id: String,
    loading: Boolean,
    modelValue: [String, Number],
    errorMessage: String,
    number: Boolean,
    placeholder: String,
    disabled: Boolean,
    maxlength: Number,
    autofocus: Boolean,
    search: Boolean,
    helpText: String,
    icon: String,
    readonly: Boolean,
    variant: {
      type: String,
      default: "outlined"
    }
  },
  emits: ["blur", "update:modelValue", "update:errorMessage"],
  methods: {
    inputHandler(event) {
      this.$emit("update:errorMessage", "");
      this.$emit("update:modelValue", this.number ? parseFloat(event.target.value) : event.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/base-input.scss";
</style>
