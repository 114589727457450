<template>
  <div class="app-container">
    <div :class="{ 'app-container__sidebar_open': isOpenSideBar }" class="app-container__sidebar">
      <Suspense>
        <side-navigation v-model:isOpenSideBar="isOpenSideBar" :menu="menu" :menu-admin="menuAdmin" />
      </Suspense>
    </div>
    <div class="app-container__page">
      <div class="app-container__page-inner">
        <Suspense @pending="loadingView = true" @resolve="loadingView = false">
          <template #default>
            <main v-show="!loadingView">
              <header-main :label="getHeaderTitle()" @toggle-side-bar="isOpenSideBar = !isOpenSideBar" />
              <component :is="view" />
            </main>
          </template>
        </Suspense>
      </div>
    </div>
  </div>
</template>

<script>
import SideNavigation from "@/components/SideNavigation/SideNavigation.vue";
import HeaderMain from "@/components/Headers/HeaderMain.vue";
import BaseIcons from "@/assets/icons/base-icons.svg";

export default {
  components: {
    SideNavigation,
    HeaderMain
  },
  props: {
    view: Object
  },
  data() {
    return {
      BaseIcons,
      loadingView: false,
      isOpenSideBar: false,
      menu: [
        { path: "/", label: "navigationLabel.home", icon: "bi bi-house" },
        { path: "/profile", label: "navigationLabel.profile" },
        { path: "/support", label: "navigationLabel.support" },
        {
          label: "navigationLabel.buy",
          icon: "bi bi-cash-coin",
          children: [
            {
              path: "/buy-ssl",
              label: "navigationLabel.buySSL",
              iconID: "ssl-certificate"
            },
            {
              path: "/buy-ssl/order",
              label: "buySSL.orderTitle"
            }
          ]
        },
        { path: "/my-ssl", label: "navigationLabel.mySSL", iconID: "ssl-certificate" },
        { path: "/orders", label: "navigationLabel.manageOrders", icon: "bi bi-bag" },
        { path: "/online-tools", label: "navigationLabel.onlineTools", icon: "bi bi-tools" },
        { path: "/online-tools/csr-generator", label: "navigationLabel.csrGenerator" },
        { path: "/online-tools/csr-decoder", label: "csrDecoder.title" },
        { path: "/invoices", label: "navigationLabel.invoices", icon: "bi bi-receipt" },
        { path: "/payments", label: "navigationLabel.payments", icon: "bi bi-credit-card" },
        { path: "/payments/success", label: "navigationLabel.payments" }
      ],
      menuAdmin: [
        { path: "/admin/settings", label: "Настройки", icon: "bi bi-gear" },
        { path: "/admin/users", label: "Пользователи", icon: "bi bi-person" }
      ]
    };
  },
  methods: {
    getHeaderTitle() {
      return (
        (
          this.menu.flatMap((item) => item.children || item).findLast((item) => this.$route.path.includes(item.path)) ||
          this.menuAdmin.flatMap((item) => item.children || item).find((item) => item.path === this.$route.path)
        )?.label || ""
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;

  &__sidebar {
    overflow: hidden auto;
    transition: all 0.2s;
    display: flex;
    justify-content: flex-end;
    background-color: var(--sidebar-background-color);

    &_open {
      left: 0 !important;
    }
  }

  &__page {
    overflow: hidden auto;
    flex: 1;

    &-inner {
      box-sizing: border-box;
      padding: 20px 30px;
    }
  }
}

@media (max-width: 850px) {
  .app-container {
    &__sidebar {
      height: 100%;
      display: block;
      width: auto;
      left: -325px;
      position: absolute;
    }

    &__page-inner {
      padding: 10px 16px;
    }
  }
}
</style>
