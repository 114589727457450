<template>
  <base-button-circle @click="changeTheme">
    <i v-if="checked" class="pi pi-moon" />
    <i v-else class="pi pi-sun" />
  </base-button-circle>
</template>

<script>
import BaseButtonCircle from "@/components/Base/BaseButtonCircle.vue";

export default {
  components: { BaseButtonCircle },
  data() {
    return {
      checked: Boolean(parseInt(localStorage.getItem("color_theme")))
    };
  },
  methods: {
    changeTheme() {
      this.checked = !this.checked;
      localStorage.setItem("color_theme", this.checked ? "1" : "0");
      const theme = this.checked ? "dark" : "light";
      document.documentElement.setAttribute("data-theme", theme);
      document.documentElement.className = theme;
    }
  }
};
</script>
