<template>
  <div ref="SegmentedPicker" class="segmented-picker">
    <label
      v-for="item in list"
      :key="item.key"
      class="segmented-picker__item"
      @change="() => selectSegmented(item.key)"
    >
      <input :id="item.key" :checked="selected === item.key" :name="name" type="radio" />
      <div class="segmented-picker__selected" />
      <span class="segmented-picker__label">{{ item.label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    selected: String,
    name: String
  },
  emits: ["update:selected"],
  mounted() {
    let selectedKey = this.selected;
    !selectedKey && (selectedKey = this.list[0].key);
    this.$emit("update:selected", selectedKey);
    this.selectSegmented(selectedKey);
  },
  methods: {
    selectSegmented(key) {
      this.$emit("update:selected", key);
    }
  }
};
</script>

<style lang="scss" scoped>
.segmented-picker {
  display: flex;
  flex-direction: row;
  padding: 4px;
  background-color: var(--canvas-overlay-color);
  border-radius: var(--border-radius-base);
  overflow-x: auto;

  &__item {
    cursor: pointer;
    flex: 1 1 0;
    position: relative;
    padding: 10px 12px;
    text-align: center;

    input {
      display: none;

      &:checked {
        & + .segmented-picker__selected {
          display: block;
        }
      }
    }
  }

  &__selected {
    border-radius: var(--border-radius-base);
    background-color: var(--canvas-default-color);
    z-index: 2;
    transition: width 0.2s ease-in-out, transform 0.2s ease-in-out;
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__label {
    align-items: center;
    height: 100%;
    font-size: 14px;
    font-weight: 600;
    z-index: 3;
    width: 100%;
    position: relative;
    display: inline-flex;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
  }
}
</style>
