export default {
  firebase: {
    apiKey: "AIzaSyBCThvw5oyB1-VDtmYwRmzRmfWCbKfk9e4",
    authDomain: "auth-sms-35bb6.firebaseapp.com",
    databaseURL: "https://auth-sms-35bb6.firebaseio.com",
    projectId: "auth-sms-35bb6",
    storageBucket: "auth-sms-35bb6.appspot.com",
    messagingSenderId: "300203536710",
    appId: "1:300203536710:web:609735cec64bd9935df547"
  }
};
