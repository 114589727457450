<template>
  <base-dropdown-menu
    class="language-selection"
    text-center
    :data="languages"
    :selected-key="$i18n.locale"
    hide-icon
    @selected="selectLanguage"
  />
</template>

<script>
import BaseDropdownMenu from "@/components/Base/BaseDropdownMenu";
import { setI18nLanguage } from "@/i18n";

export default {
  components: { BaseDropdownMenu },
  data() {
    return {
      languages: [
        { name: "РУС", key: "ru" },
        { name: "ENG", key: "en" }
      ]
    };
  },
  methods: {
    selectLanguage(item) {
      setI18nLanguage(this.$root.$i18n, item.key);
      localStorage.setItem("language", item.key);
    }
  }
};
</script>
