<template>
  <div
    class="form-control"
    :class="{
      'form-control_max-width': maxWidth,
      'form-control_disabled': disabledFull,
      'form-control_right': right
    }"
  >
    <slot name="addition" />

    <base-button class="form-control__item" :disabled="loading" @click="cancelHandler">
      {{ labelCancel || $t("profile.button.cancel") }}
    </base-button>

    <base-button
      class="form-control__item"
      :loading="loading"
      type="primary"
      html-type="submit"
      :disabled="disabled"
      @click="submitHandler"
    >
      <slot />
      {{ !$slots.default ? labelSubmit || $t("profile.button.save") : "" }}
    </base-button>
  </div>
</template>

<script>
import BaseButton from "../Base/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    loading: Boolean,
    labelSubmit: String,
    labelCancel: String,
    maxWidth: Boolean,
    disabled: Boolean,
    disabledFull: Boolean,
    right: Boolean
  },
  emits: ["cancel", "submit"],
  methods: {
    cancelHandler() {
      if (!this.disabledFull) this.$emit("cancel");
    },
    submitHandler() {
      if (!this.disabled && !this.disabledFull) this.$emit("submit");
    }
  }
};
</script>

<style lang="scss" scoped>
.form-control {
  flex-direction: row !important;
  display: flex !important;
  gap: 10px;

  &_right {
    justify-content: flex-end;
  }

  &_max-width > :deep(button) {
    flex: 1 0;
  }

  &_disabled &__item {
    opacity: 0.4;
    pointer-events: none;
  }
}

@media (max-width: 400px) {
  .form-control {
    flex-direction: column !important;
  }
}
</style>
