import { currentUser, currentUserIsAdmin, setCurrentUser } from "@/main";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      meta: { requiresAuth: true },
      components: { SidebarNav: () => import("@/views/Navigation/HomeView.vue") }
    },
    {
      path: "/buy-ssl",
      meta: { requiresAuth: true },
      children: [
        {
          path: "",
          components: { SidebarNav: () => import("@/views/Navigation/GoGetSSL/GoGetSslView.vue") }
        },
        {
          path: "order/:id",
          components: { SidebarNav: () => import("@/views/Navigation/GoGetSSL/GoGetSslOrder.vue") }
        }
      ]
    },
    {
      path: "/profile",
      meta: { requiresAuth: true },
      components: { SidebarNav: () => import("@/views/Navigation/ProfileView.vue") }
    },
    {
      path: "/my-ssl",
      meta: { requiresAuth: true },
      components: { SidebarNav: () => import("@/views/Navigation/HomeView.vue") }
    },
    {
      path: "/online-tools",
      meta: { requiresAuth: true },
      components: { SidebarNav: () => import("@/views/Navigation/OnlineTools/OnlineTools.vue") }
    },
    {
      path: "/online-tools/csr-decoder",
      meta: { requiresAuth: true },
      components: { SidebarNav: () => import("@/views/Navigation/OnlineTools/CSRDecoder/CSRDecoder.vue") }
    },
    {
      path: "/online-tools/csr-generator",
      meta: { requiresAuth: true },
      components: { SidebarNav: () => import("@/views/Navigation/OnlineTools/CSRGenerator/CSRGenerator.vue") }
    },
    {
      path: "/orders",
      meta: { requiresAuth: true },
      components: { SidebarNav: () => import("@/views/Navigation/OrdersView.vue") }
    },
    {
      path: "/invoices",
      meta: { requiresAuth: true },
      components: { SidebarNav: () => import("@/views/Navigation/HomeView.vue") }
    },
    {
      path: "/payments",
      meta: { requiresAuth: true },
      components: { SidebarNav: () => import("@/views/Navigation/PaymentsView.vue") },
      alias: "/payments/success"
    },
    {
      path: "/support",
      meta: { requiresAuth: true },
      components: { SidebarNav: () => import("@/views/Navigation/HomeView.vue") }
    },
    {
      path: "/admin",
      meta: { requiresAuth: true },
      children: [
        {
          path: "settings",
          components: { SidebarNav: () => import("@/views/AdminNavigation/AdminSettings.vue") }
        },
        {
          path: "users",
          components: { SidebarNav: () => import("@/views/AdminNavigation/AdminUsers.vue") }
        }
      ]
    },
    { path: "/login", components: { AuthPage: () => import("@/views/Navigation/LoginView.vue") } },
    { path: "/signup", components: { AuthPage: () => import("@/views/Navigation/SignupView.vue") } },
    { path: "/reset-password", components: { AuthPage: () => import("@/views/Navigation/ResetPasswordView.vue") } }
  ]
});

router.beforeEach(async (to) => {
  (currentUser === undefined || currentUserIsAdmin === undefined) && (await setCurrentUser());

  if ((to.meta.requiresAuth || to.meta.requiresAuthAdmin) && !currentUser) return { path: "/login" };
  if ((to.path === "/login" || to.path === "/signup") && currentUser) return { path: "/" };

  if (to.meta.requiresAuthAdmin && currentUser) {
    const userIsAdmin = currentUserIsAdmin || currentUserIsAdmin;
    if (!userIsAdmin) return { path: "/" };
  }
});

export default router;
