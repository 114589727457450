<template>
  <li
    v-for="item in menu.filter((elem) => elem.icon || elem.iconID)"
    :key="item.path"
    :class="[{ active: isActive(item.path) }]"
    class="side-navigation__list-item"
  >
    <router-link v-if="item.path" :to="item.path" class="side-navigation__list-title" @click="$emit('toggleSideBar')">
      <i :class="item.icon">
        <svg v-if="item.iconID" class="svg-icon-container" height="20" width="20">
          <use :xlink:href="`${BaseIcons}#${item.iconID}`" />
        </svg>
      </i>
      <span>{{ $t(item.label) }}</span>
    </router-link>

    <div v-else-if="item.children" class="side-navigation__list-title" @click="item.isOpen = !item.isOpen">
      <i :class="item.icon" />
      <span>{{ $t(item.label) }}</span>
      <i :class="{ open: item.isOpen }" class="bi bi-chevron-right" />
    </div>

    <ul v-if="item.children" v-show="item.isOpen" class="side-navigation__sub-list">
      <SideNavigationItem :menu="item.children" @toggle-side-bar="$emit('toggleSideBar')" />
    </ul>
  </li>
</template>

<script>
import BaseIcons from "@/assets/icons/base-icons.svg";

export default {
  name: "SideNavigationItem",
  props: {
    menu: Array
  },
  emits: ["toggleSideBar"],
  data() {
    return {
      BaseIcons
    };
  },
  methods: {
    isActive(item) {
      if (item === "/") return this.$route.path === "/";
      return this.$route.path.includes(item);
    }
  }
};
</script>

<style lang="scss" scoped>
.side-navigation__sub-list {
  margin-left: 22px;
  list-style: none;
  padding-left: 10px;
  border-left: 2px solid var(--sidebar-item-active-background-color);

  &:deep(li:first-child) {
    margin-top: 10px;
  }
}

.side-navigation__list {
  .bi-chevron-right {
    transition: all 0.2s;
    font-size: 14px;
    margin-right: 0;
    justify-content: end;

    &.open {
      transform: rotate(90deg);
    }
  }

  &-title {
    flex: 1;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    border: 2px solid transparent;
    border-radius: var(--border-radius-base);
    color: var(--primary-text-color);
    text-decoration: none;
    transition: var(--transition-base);
    padding: 0 12px;
    height: 40px;
    align-items: center;
    position: relative;
    cursor: pointer;

    & span {
      flex: 1;
    }

    &:hover {
      border: 2px solid var(--sidebar-item-hover-border-color);
    }
  }

  &-item {
    width: 100%;
    margin-bottom: 10px;

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      margin-right: 10px;

      .svg-icon-container {
        display: inline-block;
        vertical-align: -0.125em;
        fill: currentcolor;
      }
    }

    &.active > a {
      background-color: var(--sidebar-item-active-background-color);

      & span {
        color: var(--primary-color);
      }

      i {
        color: var(--primary-color);

        svg {
          fill: var(--primary-color);
        }
      }
    }
  }
}
</style>
