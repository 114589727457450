<template>
  <router-view />
  <router-view name="AuthPage" />
  <router-view v-slot="{ Component }" name="SidebarNav">
    <main-view v-if="Component" :view="Component" />
  </router-view>
</template>

<script>
import MainView from "./views/Navigation/MainView.vue";

export default {
  components: { MainView },
  beforeCreate() {
    const colorTheme = parseInt(localStorage.getItem("color_theme"));
    document.querySelector('meta[name="theme-color"]').setAttribute("content", colorTheme ? "#1d1d1d" : "#fff");
    const theme = colorTheme ? "dark" : "light";
    document.documentElement.setAttribute("data-theme", theme);
    document.documentElement.className = theme;
  }
};
</script>

<style lang="scss">
//noinspection CssUnknownTarget
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800;900&family=Roboto:wght@300;400;500;700&display=swap");
@import "@/assets/styles/main.scss";
</style>
